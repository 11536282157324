import React, { useState } from "react";
import { TouchableOpacity, View, KeyboardAvoidingView } from "react-native";
import Container from "../../common/Container";
import InputField from "../../common/InputField";
import ResponsiveText from "../../common/ResponsiveText";
import CommonText from "../../common/CommonText";
import Button from "../../common/Button";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../actions/User";
import { showMessage } from "react-native-flash-message";
import { onValidate } from "../../utils/helpers";
import colors from "../../config/color";

const showMessageData = { position: "top", autoHide: true, duration: 5000 };

const ForgotPassword = ({ navigation }) => {
  const dispatch = useDispatch();
  const { platform } = useSelector((state) => state.UIState.platform);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeInput = (e) => {
    setEmail(e);
  };

  const onLogin = async () => {
    const trimmedEmail = email.toLowerCase().trim();
    setEmail(trimmedEmail);
    if (!trimmedEmail.length) {
      showMessage({
        ...showMessageData,
        message: "Please enter recovered email",
        type: "danger",
      });
    } else {
      if (!onValidate("email", trimmedEmail)) {
        showMessage({
          ...showMessageData,
          message: "Invalid Email",
          type: "danger",
        });
      } else {
        setLoading(true);
        const data = await dispatch(forgotPassword(trimmedEmail));
        setLoading(false);
        if (data.success) {
          navigation.navigate("VerifyOTP", { forgot: true });
        }
      }
    }
  };

  return (
    <Container style={styles.container}>
      <KeyboardAvoidingView
        behavior={platform ? "padding" : "height"}
        style={styles.container}
      >
        <View style={styles.headerContainer}>
          <CommonText type="heading">Assessment Manager</CommonText>
        </View>
        <View
          style={[
            styles.fieldContainer,
            { paddingHorizontal: platform ? wp("3%") : wp("5%") },
          ]}
        >
          <CommonText type="heading">Enter your email to recover</CommonText>
          <InputField
            placeholder="Email"
            value={email}
            onChangeText={(e) => onChangeInput(e)}
          />
          <Button loading={loading} text="Recover" onPress={onLogin} />
          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => navigation.goBack()}
          >
            <ResponsiveText style={{ color: colors.secondaryText}}>Login?</ResponsiveText>
          </TouchableOpacity>
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default ForgotPassword;

const styles = {
  headerContainer: {
    height: wp("10%"),
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
  },
  fieldContainer: {
    flex: 1,
    justifyContent: "center",
  },
};
