import React from "react";
import { Text } from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useSelector } from "react-redux";
import Color from "../config/color";

const ResponsiveText = (props) => {
  const { platform } = useSelector((state) => state.UIState.platform);

  const { style, children } = props;
  let fontSize = platform ? wp("1%") : wp("4%");
  if (style && style.fontSize) {
    fontSize = wp(style.fontSize);
  }
  const numberOfLines = props.numberOfLines ? props.numberOfLines : 0;
  return (
    <Text
      numberOfLines={numberOfLines}
      style={{
        ...styles.text,
        ...props.style,
        ...{
          fontSize,
        },
      }}
    >
      {children}
    </Text>
  );
};

export default ResponsiveText;

const styles = {
  text: {
    color: Color.secondaryText,
  },
};
