import React from "react";
import { TouchableOpacity, View, ActivityIndicator } from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import ResponsiveText from "./ResponsiveText";
import Color from "../config/color";
import colors from "../config/color";
import { isBrowserScreen } from "../utils/helpers";

const Button = (props) => {
  const fontSize =
    props.textStyle && props.textStyle.fontSize
      ? props.textStyle.fontSize
      : undefined;
  const { thin, medium } = props;

  return (
    <TouchableOpacity
      disabled={props.loading || props.disabled}
      onPress={props.onPress}
      style={[
        styles.ButtonStyle,
        {
          height: isBrowserScreen() ? wp("2.5%") : thin ? wp("10%") : wp("12%"),
        },
        props.containerStyle,
      ]}
    >
      <View style={[styles.leftStyle, props.leftIconStyle]}>
        {props.leftIcon}
      </View>
      {(props.loading && (
        <ActivityIndicator size={"small"} color={colors.primaryText} />
      )) ||
        (props.text && (
          <ResponsiveText
            medium={medium}
            style={{ ...props.textStyle, fontWeight: "bold" }}
          >
            {props.text}
          </ResponsiveText>
        ))}
      <View style={[styles.rightStyle, props.rightIconStyle]}>
        {props.right}
      </View>
    </TouchableOpacity>
  );
};

export default Button;

const styles = {
  ButtonStyle: {
    borderWidth: 1,
    borderColor: Color.transparent,
    marginVertical: 10,
    shadowColor: colors.secondaryText,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Color.primary,
    borderRadius: wp("7%"),
    paddingHorizontal: "5%",
  },
  textStyle: {
    marginVertical: wp("10%"),
    color: Color.secondaryText,
  },
  leftStyle: {},
  rightStyle: {},
};
