import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../../common/AppHeader";
import Container from "../../../common/Container";
import Loading from "../../../common/Loading";
import Icons from "../../../config/icons";
import { TouchableOpacity, View, FlatList } from "react-native";
import ResponsiveText from "../../../common/ResponsiveText";
import colors from "../../../config/color";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAssessments,
  setAssessmentDetail
} from "../../../actions/ManageAssessments";
import { useFocusEffect } from "@react-navigation/native";
import InputField from "../../../common/InputField";

const ManageAssessment = ({ navigation }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filterArray, setFilterArray] = useState([]);
  const { allAssessments } = useSelector((state) => state.AppState.assessments);

  useFocusEffect(
    useCallback(() => {
      setLoading(true);
      dispatch(getAllAssessments());
      setLoading(false);
    }, [])
  );

  const handleAssessmentDetail = (item) => {
    dispatch(setAssessmentDetail(item));

    //navigate to assessment detail
    navigation.navigate("AssessmentDetail");
  };

  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => handleAssessmentDetail(item)}
        style={styles.header}
      >
        <ResponsiveText style={styles.text}>
          {item?.user?.name || ""} ({item?.assessment?.code || ""})
        </ResponsiveText>
        {Icons.rightArrow()}
      </TouchableOpacity>
    );
  };

  const _onSearch = (e) => {
    const filtered = allAssessments.filter((item) => {
      const newItem = item.user.name.toLowerCase() + " (" + item.assessment.code.toLowerCase() + ")";
      return newItem.includes(e.trim().toLowerCase())
    }
    );
    setFilterArray([...filtered]);
  };

  useEffect(() => {
    setFilterArray([...allAssessments]);
  }
    , [allAssessments]);


  return (
    <Container>
      <AppHeader
        body="Manage Assessments"
        leftPress={() => navigation.goBack()}
        left={Icons.back()}
      />
      <View style={styles.container}>
        <InputField placeholder={`Search by Name`} onChangeText={_onSearch} />
        {loading ? (
          <Loading />
        ) : !!allAssessments.length ? (
          <FlatList
            data={filterArray}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
          />
        ) : (
          <View style={styles.noData}>
            <ResponsiveText style={styles.noDataText}>
              No data found
            </ResponsiveText>
          </View>
        )}
      </View>
    </Container>
  );
};

export default ManageAssessment;

const styles = {
  container: {
    paddingHorizontal: 10,
    flex: 1,
    justifyContent: "center",
    marginBottom: 10
  },
  noData: { flex: 1, justifyContent: "center", alignItems: "center" },
  noDataText: {
    fontWeight: "bold",
    alignText: "center",
    color: colors.borderColor
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.background,
    paddingVertical: 20,
    borderRadius: 10,
    marginTop: 10,
    paddingHorizontal: 5
  },
  text: {
    color: colors.SecondaryText,
    marginLeft: 10
  },
  kpiText: {
    paddingVertical: 12,
    color: colors.SecondaryText
  },
  headerText: {
    color: colors.SubHeading
  },
  content: {
    backgroundColor: colors.PrimaryText
  },
};
